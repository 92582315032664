<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>用户管理</template>
      <template v-slot:secondMenu>店长申请</template>
    </breadcrumb-nav>
    <el-card>
      <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
      <el-form-item label="申请状态" prop="status">
        <el-select v-model="listQuery.status" style="width:100%" @clear="cancelStatus" clearable placeholder="选择申请状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请日期" prop="date">
        <el-date-picker
          v-model="listQuery.date"
          type="daterange"
          align="right"
          prop="date"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="resetForm('listQuery')">重置</el-button>
      </el-form-item>
    </el-form>
      <el-table class="custom-order-table" :data="list" border stripe>
        <el-table-column prop="name" label="姓名" width="120px"></el-table-column>
        <el-table-column prop="mobile" label="电话" width="150px"></el-table-column>
        <el-table-column prop="detailAddr" label="详细地址"></el-table-column>
        <el-table-column label="申请时间" prop="createTime" width="200px" :formatter="formatDate"></el-table-column>
        <el-table-column prop="applyStatus" label="申请状态" width="150px">
          <template slot-scope="scope">{{ scope.row.applyStatus | formatApplyStatus }}</template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-row>
              <el-button type="primary" v-if="scope.row.applyStatus!==1" size="mini" icon="el-icon-view" @click="auditDetail(scope.row.applyId, scope.row.userId)">详情
              </el-button>
              <el-button type="warning" v-if="scope.row.applyStatus===1" size="mini" icon="el-icon-postcard" @click="auditDetail(scope.row.applyId, scope.row.userId)">审核
              </el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[10, 20]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { listTeamApply } from '../../../network/user'

export default {
  name: 'UserTeamApply',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatApplyStatus (value) {
      switch (value) {
        case 1:
          return '申请中'
        case 2:
          return '申请通过'
        case 3:
          return '申请拒绝'
        default:
          return '未知'
      }
    }
  },
  data () {
    return {
      listQuery: {
        status: '',
        date: '',
        page: 1, // 当前页
        size: 10 // 每页显示多少条数据
      },
      list: [],
      total: 0,
      statusOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '申请中',
          value: 1
        },
        {
          label: '申请通过',
          value: 2
        },
        {
          label: '申请拒绝',
          value: 3
        }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    formatDate (row, column) {
      const value = row.createTime
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    getList () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        status: this.listQuery.status
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.startTime = this.listQuery.date[0]
        query.endTime = this.listQuery.date[1]
      }
      listTeamApply(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.detailAddr = item.province + item.city + item.district + ' ' + item.address
        }
        this.total = result.data.total
      })
    },
    search () {
      this.getList()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    cancelStatus () {
      this.listQuery.status = 0
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.search()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.search()
    },
    auditDetail (applyId, userId) {
      this.$router.push({ path: '/userTeamApplyDetail', query: { applyId: applyId, userId: userId } })
    }
  }
}
</script>

<style scoped>

</style>
